const styles = _ => ({
  maintenanceContainer: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '30px 40px',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: '650px',
    minWidth: '300px',
    top: '20%',
  },
});

export default styles;
