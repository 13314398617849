const languagesInLocalTranslation = [
  {
    locale: 'en',
    full: 'English',
  },
  {
    locale: 'de',
    full: 'Deutsch',
  },
  {
    locale: 'fr',
    full: 'Français',
  },
  {
    locale: 'hr',
    full: 'Croatian',
  },
  {
    locale: 'nl',
    full: 'Nederlands',
  },
  {
    locale: 'es',
    full: 'Español',
  },
  {
    locale: 'ro',
    full: 'Romanian',
  },
  {
    locale: 'it',
    full: 'Italiano',
  },
  {
    locale: 'dk',
    full: 'Dansk',
  },
];
export default languagesInLocalTranslation;
