import { SELECT_LANGUAGE } from '../types/selectLanguage';
import { getInitialLanguage } from '../i18n/index';

const initialState = {
  language: getInitialLanguage(),
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_LANGUAGE:
      return { ...state, language: action.value };
    default:
      return state;
  }
};
export default appReducer;
