const i18nIT = {
  MS_GDPR_TITLE: 'Richiesta di accesso remoto al tuo impianto di riscaldamento',
  MS_GDPR_DETAILEDTITLE:
    "{company} ha richiesto l'accesso remoto al tuo sistema di riscaldamento.",
  MS_GDPR_SUBTITLE:
    'Affinchè {company} possa fornire assistenza remota sul tuo impianto, è necessario che gli venga fornito il permesso ad accedere ai dati del tuo sistema di riscaldamento.',
  MS_USERINFO_TITLE: 'La tua informazione:',
  MS_SALUTATION: 'Appellativo',
  MS_SALUTATION_LABEL: 'Sig./Sig.ra',
  MS_SALUTATION_MRS: 'Sig.ra',
  MS_SALUTATION_MR: 'Sig.',
  MS_TITLE: 'Titolo',
  MS_FIRSTNAME: 'Nome',
  MS_LASTNAME: 'Cognome',
  MS_NAME: 'Nome',
  MS_STREET: 'Indirizzo',
  MS_HOUSENUMBER: 'Civico',
  MS_ADDRESS_EXT: 'Informazioni aggiuntive indirizzo',
  MS_ZIPCODE: 'CAP',
  MS_CITY: 'Città',
  MS_COUNTRY: 'Nazione',
  MS_PHONE: 'Telefono',
  MS_EMAIL: 'E-mail',
  MS_MANDATORY: 'Campi obbligatori',
  MS_PHONE_TOO_SHORT: 'Numero di telefono troppo corto',
  MS_PHONE_TOO_LONG: 'Numero di telefono troppo lungo',
  MS_CONFIRMATION_TITLE: 'Convalida la dichiarazione di consenso:',
  MS_CONFIRMATION_PRE_TEXT: 'Fornisco il consenso al',
  MS_CONFIRMATION_TEXT:
    'trattamento dei dati tramite il centro di assistenza/azienda',
  MS_CONFIRMATION_POST_TEXT: '{company}.',
  MS_CONFIRMATION_BUTTON: 'INVIA IL CONSENSO',
  MS_IMPRINT: 'Nota legale',
  MS_GDPR: 'Privacy policy',
  MS_TERMS: 'Condizioni di utilizzo',
  MS_VAILLANT_LOGO_MOTO: 'Comfort per la tua casa',
  MS_DATA_PROTECTION: 'Privacy',
  MS_REQUIRED: 'Campo obbligatorio',
  MS_REQUIRED_EXPLANATION:
    '* Questo campo è obbligatorio e deve essere compilato per procedere',
  MS_INVALID_ZIP_CODE: 'CAP non valido',
  MS_CLOSE_BUTTON: 'Chiudi',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Inizia il numero con',
  MS_EXPANDABLE_TITLE: 'Dichiarazione di consenso',
  MS_NO_PARAM_GRANTED_CRAFTSMAN:
    "Hai già concesso l'accesso a un installatore/centro assistenza.",
  MS_NO_PARAM_OPEN_VAILLANT_MAIL:
    'Se vuoi revocare il consenso, per favore apri la mail che hai ricevuto in fase di registrazione e clicca su "clicca qui" per procedere.',
  MS_NO_PARAM_QUESTIONS_TO_VAILLANT:
    'Per ulteriori domande contatta il tuo installatore/fornitore o invia una email a {0}.',
  MS_ABOUT_US: 'Su di noi',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'Visita la pagina {0}.',
  MS_OPTOUT_SALUTATION: 'Gentile Cliente,',
  MS_OPTOUT_QUESTION:
    'vuoi procedere e revocare il consenso al trattamento dei tuoi dati (opt-out)?',
  MS_OPTOUT_CHECKBOX:
    'Sì, consapevole che così il centro assistenza/installatore non potrà più accedere ai dati del mio impianto.',
  MS_OPTOUT_CONFIRM: 'Si, revoca',
  MS_OPT_IN_SUCCESS: 'Il tuo consenso è stato correttamente trasmesso.',
  MS_OPTOUT_SUCCESS: 'La revoca (opt-out) è avvenuta con successo.',
  MS_OPTOUT_FAIL:
    "Qualcosa è andato storto durante l'elaborazione della tua richiesta di revoca. Per cortesia contatta la nostra assistenza.",
  MS_IMPRINT_TITLE: 'Note legali',
  MS_IMPRINT_PROVIDER: 'Fornitore',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Telefono:',
  MS_IMPRINT_LTD: 'Società a responsabilità limitata',
  MS_IMPRINT_REGISTERED: 'Sede Legale:',
  MS_IMPRINT_REGISTRYCOURT: 'Registro delle imprese:',
  MS_IMPRINT_MANAGING: 'Direttore:',
  MS_IMPRINT_CHAIRMAN: 'Presidente del consiglio di sorveglianza:',
  MS_IMPRINT_VAT: 'P.IVA',
  MS_GENERIC_ERROR_TITLE: 'Ops',
  MS_GENERIC_ERROR_EXPLANATION:
    "Si è verificato un errore durante l'leaborazione della tua richiesta, per favore riprova.",
  MS_TOKEN_NOT_FOUND_ERROR_TITLE:
    'Ci spiace, non possiamo gestire questa richiesta',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    "Per cortesia sincerati di utilizzare l'URL corretto che ti abbiamo inviato via mail.",
  MS_MAINTENANCE_MESSAGE_TITLE: 'In manutenzione',
  MS_MAINTENANCE_MESSAGE:
    'È in corso una manutenzione. La finestra di manutenzione termina il {endOfMaintenanceWindow, date, medium} al {endOfMaintenanceWindow, time, short}.',
};

export default i18nIT;
