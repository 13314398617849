import ThemeColors from '../../Colors/index';

const styles = theme => ({
  registrationForm: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  submitBtn: {
    padding: 20,
    width: 180,
    marginTop: 20,
    height: 60,
    backgroundColor: ThemeColors.PRIMARY,
    color: ThemeColors.WHITE,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export default styles;
