import axiosInstance from './HttpClient';

export class OptInClient {
  static getPath = token => `consent/${token}`;
  static getPathMaintenance = token =>
    `consent/${token}/country-maintenance-window`;

  static async hasActiveMaintenanceWindow(token): Promise<Date> {
    try {
      const result = await axiosInstance.get(this.getPathMaintenance(token));
      return new Date(result.data.end);
    } catch (e) {
      return null;
    }
  }

  static async getPrefillData(token) {
    try {
      const result = await axiosInstance.get(this.getPath(token));
      if (result.status >= 200 && result.status < 300) {
        return {
          error: false,
          email: result.data.customerEmail,
          firstName: result.data.customerFirstName,
          lastName: result.data.customerLastName,
          company: result.data.companyName,
          consentGiven: result.data.consentGiven,
          country: result.data.country,
          status: result.status,
          personalDataRequired: result.data.endCustomerPersonalDataRequired,
          token,
        };
      } else {
        return {
          status: result.status,
          error: true,
          message: 'Could not fetch data from backend',
        };
      }
    } catch (e) {
      return {
        status: (e.response && e.response.status) || -1,
        error: true,
        message: 'Could not fetch data from backend',
      };
    }
  }

  static async giveConsent(token, user) {
    try {
      const result = await axiosInstance.put(this.getPath(token), user);
      return {
        error: result.status < 200 || result.status > 300,
        status: result.status,
      };
    } catch (e) {
      return {
        status: e.response && e.response.status,
        error: true,
        message: 'Could not fetch data from backend',
      };
    }
  }

  static async revokeConsent(token) {
    try {
      const result = await axiosInstance.delete(this.getPath(token));
      return {
        error: result.status < 200 || result.status > 300,
        status: result.status,
      };
    } catch (e) {
      return { error: true, status: e.response && e.response.status };
    }
  }
}
